import "./AdminDashboard.scss";
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import { Authenticator, useTheme, View, Image, Text, Heading } from '@aws-amplify/ui-react';
import logo from "./assets/indoorify-logo.svg";
import { fetchUserAttributes, signOut } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import TimeSlot from "./Timeslot";

Amplify.configure(awsconfig);

function AdminDashboard() {
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getUserDetails() {
      try {
        const user = await fetchUserAttributes();

        setUser(user);
      } catch (error) {
        console.error("Error fetching futsals:", error);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    }

    getUserDetails();
  }, []);

  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Heading
            level={4}
          >
              Login to your<br/>Admin Account
          </Heading>
        </View>
      );
    },
    
    Footer() {
        const { tokens } = useTheme();
    
        return (
          <View textAlign="center" padding={tokens.space.large}>
            <Text color={tokens.colors.neutral[80]}>
              &copy; All Rights Reserved
            </Text>
          </View>
        );
      },

      SignIn: {
        Header() {
          const { tokens } = useTheme();
    
          return (
            <View textAlign="center" padding={tokens.space.large}>
              <Image
                alt="indoortime logo"
                src={logo}
                width="250px"
              />
            </View>
          );
        }
      }
};

  return (
    <div id="AdminDashboard">
      <Authenticator socialProviders={['']} variation="modal" components={components}>
        <div id="heading">
          <span>Admin Dashboard</span>
          <h1 className="headUpper">{user.name}</h1>
        </div>

        <section id="confirmedBookings">
          <div className="wrapper">
            <h2>Confirmed Bookings</h2>
            <div className="filter">
              <span>Choose Date to filter Bookings:</span>
              <input type="date"></input>
            </div>
            <table>
              <tr>
                <td className="name">Azam Mohamed</td>
                <td className="timeSlot" rowSpan={2}>
                  11 AM - 12 PM
                </td>
              </tr>
              <tr>
                <td className="phoneNumber">0774306800</td>
              </tr>
            </table>
            <table>
              <tr>
                <td className="name">Ishfaq Iqbal</td>
                <td className="timeSlot" rowSpan={2}>
                  5 PM - 6 PM
                </td>
              </tr>
              <tr>
                <td className="phoneNumber">0701460789</td>
              </tr>
            </table>
          </div>
        </section>

        <section id="manualBookings">
          <div className="wrapper">
            <h2>Manual Bookings</h2>
            <div className="date">
              <span>Date:</span>
              <input type="date"></input>
            </div>
            <div className="time">
              <TimeSlot/>
            </div>
            <div className="name">
              <span>Name:</span>
              <input type="text"></input>
            </div>
            <div className="phone">
              <span>Phone:</span>
              <input type="text"></input>
            </div>
            <button>BOOK</button>
          </div>
        </section>

        <section id="sales">
          <div className="wrapper">
            <div className="salesDivision">
              <div className="salesHeading">
                <span>September</span>
                <h1>Total Slots Sold</h1>
              </div>
              <table>
                <tr>
                  <th>Online</th>
                  <th>Manual</th>
                </tr>
                <tr>
                  <td>100</td>
                  <td>20</td>
                </tr>
              </table>
            </div>
            <div className="totalSold">
              <span className="soldHeading">Total Sold</span>
              <span className="soldNumbers">120</span>
            </div>
          </div>
        </section>

        <section id="bill">
          <div className="wrapper">
            <span>August</span>
            <h1>Pending Monthly Bill</h1>
            <div className="billDivision">
              <div className="invoice">
                <span className="billedSlots">100</span>
                <span className="billedAt">* Rs.60 Per Slot</span>
                <button>Download Detailed Invoice</button>
              </div>
              <div className="invoiceAction">
                <button>Make Payment</button>
                <span>OR</span>
                <button>Upload Slip</button>
              </div>
            </div>
          </div>
        </section>

        <section id="manualBookings">
          <div className="wrapper">
            <h2>Block Bookings</h2>
            <div className="date">
              <span>Date:</span>
              <input type="date"></input>
            </div>
            <div className="time">
              <TimeSlot/>
            </div>
            <button>BLOCK BOOKING</button>
          </div>
        </section>
      </Authenticator>
    </div>
  );
}

export default AdminDashboard;
