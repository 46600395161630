import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import HamMenu from './HamMenu';
import Nav from './Nav';
import Profile from './Profile';
import NotFound from './NotFound';

function App() {
    return (
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<AdminDashboard/>} />
            <Route path='/Profile' element={<Profile />} />
            <Route path='*' element={<NotFound/>}/>
          </Routes>
          <Nav />
          <HamMenu />
        </BrowserRouter>
      );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

